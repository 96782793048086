import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import HeadTags from "shared/HeadTags";
import img1 from 'assets/images/delivery-page_01.jpg';
import img2 from 'assets/images/delivery-page_02.jpg';
import ym from 'react-yandex-metrika';

const DeliveryPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    ym('hit', '/dostavka');
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [navigate]);

  return (
    <div className="container main-content delivery-page">
      <HeadTags
        title='Доставка стройматериалов по Орлу и области - интернет-магазин Универсал'
        metaDescription='Условия доставки строительных материалов при покупке в сети магазинов "Универсал" на нашем сайте.'
      />
      <ul className="breadcrumbs" itemScope itemType="http://schema.org/BreadcrumbList">
        <li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
          <Link to="/" itemProp="item">Главная</Link>
          <meta itemProp="position" content="1" />
        </li>
        <li className="breadcrumb-item active" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
          <span itemProp="item">Доставка</span>
          <meta itemProp="position" content="2" />
        </li>
      </ul>
      <h1><b>Доставка стройматериалов по Орлу и области</b></h1>
      <p>Строительство или ремонт — приятное, но довольно хлопотное занятие. Приходится решать массу задач — выбрать материалы, купить и найти, на чем их привезти.</p>
      <div className="img-row">
        <img alt="Доставка стройматериалов - фото 1" src={img1} title="Доставка стройматериалов - фото 1" />
        <img alt="Доставка стройматериалов - фото 2" src={img2} title="Доставка стройматериалов - фото 2" />
      </div>
      <p>С транспортировкой поможет наша компания. Мы сделаем все, чтобы ваши работы не простаивали. Вы оформляете заказ, мы — привозим товар в день покупки или на следующий. Банки с красками, фанера, пиломатериалы, гипсокартон, стекло - опытные водители аккуратно и быстро доставят любой груз по нужному адресу.</p>
      <p>Цена рассчитывается индивидуально, в зависимости от объема, веса, расстояния, срочности и других условий.</p>
      <p className="delivery-page_cars">Предлагаем два автомобиля на выбор: <b>«Рено» грузоподъемностью 1,5 т и «Форд» на 2,5 т.</b></p>
      <p className="delivery-page_phones">Ждем ваших звонков по телефону: <a href="tel:84862510038" rel="nofollow noopener noreferrer">(4862) 51-00-38</a></p>
    </div>
  )
}
export default DeliveryPage;