import { Link, NavLink } from "react-router-dom";
import { CartInfoItem } from "services/api/types/Cart";
import LoadingBlock from "shared/LoadingBlock";
import { useAddToCartMutation, useDeleteFromCartMutation } from "services/api/api";
import {useGetCartInfoQueryInitial} from "app/hooks";
import { useState } from "react";
import OrderForm from "./OrderForm";
import HeadTags from "shared/HeadTags";
import NoImage from "shared/NoImage";

const CartItem = ({cartItem}: {cartItem: CartInfoItem}) => {
	const cartId = localStorage.getItem('cartId');
	const {product, quantity} = cartItem;
	
	const [addToCart] = useAddToCartMutation();
	const [deleteFromCart] = useDeleteFromCartMutation();
	const [deleteFromCartIsLoading, setDeleteFromCartIsLoading] = useState(false);

	const handleRemoveProduct = () => {
		setDeleteFromCartIsLoading(true);
		deleteFromCart({body: {product_id: product.id, quantity}, id: cartId});
	};
	const handleDecrement = () => {
		deleteFromCart({body: {product_id: product.id, quantity: 1}, id: cartId});
	};
	const handleIncrement = () => {
		addToCart({body: {product_id: product.id, quantity: 1}, id: cartId});
	};

	return (
		<LoadingBlock isLoading={deleteFromCartIsLoading}>
			<tr className="cart_item">
				<td className="cart_item__img">
					<Link to={`/product/${product.slug}`}>
						{product.images.length 
							? 	<img src={product.images[0].thumbnail} alt={`${product.name}} - фото`} title={`${product.name}} - магазин Универсал в Орле`} />
							: 	<NoImage/>
						}
					</Link>
				</td>
				<td className="cart_item__name">
				<Link to={`/product/${product.id}`}>{product.name}</Link>
				</td>
				<td>
					<div className="quant_btns">
						<button className="good-btn minus" onClick={handleDecrement}>-</button>
						<input className="counter_value" type="text" value={quantity} />
						<button className="good-btn plus" onClick={handleIncrement}>+</button>
					</div>
				</td>
				<td><span className="cart_item__price"><b>{product.price}</b> руб.</span></td>
				<td><span className="cart_item__summ"><b>{Number(product.price)*quantity}</b> руб.</span></td>
				<td><button className="cart_remove" title="Удалить товар" onClick={handleRemoveProduct}></button></td>
			</tr>
		</LoadingBlock>
	);
};
const Cart = () => {
	const [orderSuccess, setOrderSuccess] = useState(false);
	const cartId = localStorage.getItem('cartId');
	const {data, isLoading} = useGetCartInfoQueryInitial(cartId);

	return (
		<div className="container main-content cart-page">
			<HeadTags
				title='Корзина'
				metaDescription='Корзина' />
			<ul className="breadcrumbs" itemScope itemType="http://schema.org/BreadcrumbList">
				<li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
					<NavLink to="/" itemProp="item" >Главная</NavLink>
					<meta itemProp="position" content="1" />
				</li>
				<li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
					<span itemProp="item" >Корзина</span>
					<meta itemProp="position" content="2" />
				</li>
			</ul>
			<h1><b>Корзина</b></h1>
			<LoadingBlock isLoading={isLoading}>
				<>
					{
						data && data.items.length
						?   <>
								<table className="cart_table">
									<thead>
										<tr>
											<th colSpan={2}>Название товара</th>
											<th>Количество</th>
											<th>Цена</th>
											<th>Сумма</th>
											<th>Удалить</th>
										</tr>
									</thead>
									<tbody id="table_body">
										{data.items.map(el => <CartItem cartItem={el} key={el.product.id} />)}
									</tbody>
								</table>
								<div className="cart_total">
									Итого: <span id="total_sum">{data.total}</span><small> руб.</small>
								</div>
								<h2 className="cart_form__title">Оформление заказа</h2>
								<OrderForm cartId={cartId} setOrderSuccess={setOrderSuccess}/>    
							</>
						:   <>
								{orderSuccess && 
									<div className="cart__success">
										<p>Ваш заказ отправлен</p>
										<p>Наш менеджер свяжется с вами в ближайшее время</p>
									</div>
								}
								<p>Корзина пуста</p>   
							</>
					}
				</>
			</LoadingBlock>
		</div>
	)
};
export default Cart;