import { useState } from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import marker from 'assets/images/ic-loc.png';

const MapModal = () => {
	const [mapCenter, setMapCenter] = useState([52.9866640, 36.0418200]);

	return (
		<div id="map" className="modals-content">
			<div className="modals-inner modals-inner-map">
				<div className="modals-inner-capt text-center"> Мы здесь: </div>
				<div className="contacts-item">
					<span className="contacts-item-inner">г. Орел, Наугорское шоссе, д. 19, стр. 1</span> |
					<span className="small-info" onClick={() => setMapCenter([52.9866640, 36.0418200])}>На карте</span>
				</div>
				<div className="contacts-item">
					<span className="contacts-item-inner">Орловская область, г. Болхов, ул. Тургенева, д. 78</span> |
					<span className="small-info" onClick={() => setMapCenter([53.437331, 36.005375])}>На карте</span>
				</div>
				<div className="contacts-item">
					<span className="contacts-item-inner">Орловская область, пгт. Кромы, ул. 25 Октября, д. 29</span> |
					<span className="small-info" onClick={() => setMapCenter([52.691236, 35.775280])}>На карте</span>
				</div>
				<div className="contacts-item">
					<span className="contacts-item-inner">Склад оптово-розничной торговли: Орловская область, Кромской район, д. Черкасская, пер. Маслозаводской, д. 1а</span> |
					<span className="small-info" onClick={() => setMapCenter([52.702310, 35.783563])}>На карте</span>
				</div>
				<div id="mapel" className="mr-tp-20 map" data-map data-address="г. Орел, Наугорское шоссе, д. 19, стр. 1" data-coords="52.9866640, 36.0418200">
					<YMaps>
						<Map
							state={{
								center: mapCenter,
								zoom: 16,
							}}
							width={'100%'}
							height={'100%'}
						>
							<Placemark
								geometry={mapCenter}
								options={{
									openHintOnHover: false,
									iconLayout: 'default#image',
									iconImageHref: marker,
									iconImageSize: [77, 78],
									iconImageOffset: [-40, -70]
								}}
							/>
						</Map>
					</YMaps>
				</div>
			</div>
		</div>
	)
};
export default MapModal;