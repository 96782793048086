import { useState} from "react";
import { Link, useParams } from "react-router-dom";
import { useGetProductsListQuery } from 'services/api/api';
import AsideMenu from "features/Catalog/AsideMenu";
import LoadingBlock from 'shared/LoadingBlock';
import CatalogListItem from "features/Catalog/CatalogListPage/CatalogListItem";
import Pagination from "shared/Pagination";
import HeadTags from "shared/HeadTags";

const SearchResultPage = () => {
	const {keyword} = useParams();    
	const [limit, setLimit] = useState<number>(24);
	const [offset, setOffset] = useState<number>(0);
	const [page, setPage] = useState<number>(1);
	const {data, isLoading} = useGetProductsListQuery({name: keyword, limit, offset});

	const goodList = data 
	? data.results.map(product => {
			return (
				<CatalogListItem key={product.id} product={product}/>
			)
		}) 
	: <p className="catalog_fill">
			Каталог на стадии наполнения, не все товары сейчас представлены на сайте.<br/>Консультации по ассортименту и ценам по <b>тел. <a href="tel:+74862510038">(4862) <span>51-00-38</span></a></b> или <b><a href="mailto:universal57@bk.ru" rel="nofollow noopener noreferrer" target="_blank">universal57@bk.ru</a></b>
		</p>;
	
	return (
		<>
			<HeadTags
				title='Результаты поиска стройматериалов в интернет-магазине Универсал'
				metaDescription='Результаты поиска'
			/>
			<div className="container mr-tp-20 mr-bt-20">
				<ul className="breadcrumbs" itemScope itemType="http://schema.org/BreadcrumbList">
					<li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
						<Link to="/" itemProp="item" >Главная</Link>
					</li>
					<li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
						<span itemProp="item">Результаты поиска</span>
					</li>
				</ul>
				<div className="row mr-tp-20 catalog_wrapper">
					<AsideMenu/>
					<div className="col-xs-12 col-sm-12 col-md-9 catalog_content">
						<LoadingBlock isLoading={isLoading}>
							<>
								<h1>Результаты поиска: "{keyword}"</h1>
								<div className="row row-flex category-item" itemScope itemType="http://schema.org/ItemList">
									{goodList}
								</div>
								{data &&
									<Pagination
										pageSize={limit}
										currentPage={page}
										totalCount={data.count}
										onPageChange={page => {
											if (page === Infinity) {
												setLimit(data.count);
												setOffset(0);
												setPage(1);
											} else {
												setOffset((page-1)*limit);
												setPage(page);
											}
										}}
									/>
								}
							</>
						</LoadingBlock>
					</div>
				</div>
			</div>
		</>
	)
}
export default SearchResultPage;