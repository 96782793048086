import { useParams } from 'react-router-dom';
import { Dictionary } from '@reduxjs/toolkit';
import { useGetCartInfoQuery } from 'services/api/api';

let prevParams: Dictionary<number> = {};
const useParamInt: (key: string) => [number, boolean] = (key) => {
  const params = useParams();
  const val = params[key] ? parseInt(params[key]) : null;
  const changed = prevParams[key] !== undefined && prevParams[key] !== val;
  prevParams[key] = val;
  return [val, changed];
};

export default useParamInt;

const useGetCartInfoQueryInitial = (cartId: string) => {
  let response = useGetCartInfoQuery(cartId);

  if (response && response.error && response.error['status'] === 404 ) {
    response.data = {
      total: 0,
      items: []
    };
  }

  return response;
};

const useGetCookie = (name: string) => {
  const matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export { useGetCartInfoQueryInitial, useGetCookie };