import React, { Component, ErrorInfo, ReactNode, useState, useEffect  } from "react";
import { useLocation } from "react-router-dom";
import NotFound from "./NotFound";

interface Props {
  children?: ReactNode;
  hasError?: boolean,
  setHasError: any
}
interface State {
  hasError: boolean;
}
const ErrorComponent = () => {
  return (
    <div className="errorpage__center">
      <div className="errorpage">
        <div className="container main-content center-text">
          <div className="main-content-inners ">
            <div className="title">
              <h1><b style={{fontSize: 60}}>Ошибка</b></h1>
            </div>
            <div className="text">
              <p>Извините!<br/> Произошла ошибка...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

/**
 * NEW: The error boundary has a function component wrapper.
 */
export function ErrorBoundary({children}) {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
  }, [location.key]);
  return (
    /**
     * NEW: The class component error boundary is now
     *      a child of the functional component.
     */
    <ErrorBoundaryInner 
      hasError={hasError} 
      setHasError={setHasError}
    >
      {children}
    </ErrorBoundaryInner>
  );
}

/**
 * NEW: The class component accepts getters and setters for
 *      the parent functional component's error state.
 */
class ErrorBoundaryInner extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidUpdate(prevProps, _previousState) {
    if(!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.setHasError(true);
  }

  render() {
    return this.state.hasError
      ? <NotFound />
      : this.props.children; 
  }
}