import Helmet from "react-helmet";

const HeadTags = (props) => {
  const {
    title = "Universal 57",
    metaDescription = "Universal 57",
  } = props;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      <meta name="title" key="title" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content="ru_RU" />
      <meta property="og:type" content="article" />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content="http://universal57.ru/img/logo_new.png" />  
      <meta property="og:url" content={document.URL}></meta>
    </Helmet>
  );
};
export default HeadTags;