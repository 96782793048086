const ModalPopup = ({setShow, children, addedClass}: {setShow: (boolean) => void, children: JSX.Element, addedClass?: string}): JSX.Element => {
	
	return (
		<div className={`modal ${addedClass}`} onClick={() => setShow(false)}>
			<div className="modal__inner" onClick={e => e.stopPropagation()}>
				{children}
				<button type="button" className="modal__close" title="Close" onClick={() => setShow(false)}>
					<svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>
				</button>
			</div>
		</div>
	);
  };
  
  export default ModalPopup;