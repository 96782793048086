import { Link } from "react-router-dom";
import { useState } from "react";
import { useCreateOrderMutation } from "services/api/api";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import MaskedInput from "react-text-mask";
import { Order } from "services/api/types/Order";
import FormResult from "shared/FormResult";
import ModalPopup from "shared/ModalPopup";

const OrderForm = ({cartId, setOrderSuccess}: {cartId: string, setOrderSuccess: (boolean) => void}) => {
	const [createOrder] = useCreateOrderMutation();
	const [resultShow, setResultShow] = useState(false);
    const [resultText, setResultText] = useState({
        status: '',
        text: ''
    });
	const phoneNumberMask = [
		"+",
		"7",
		"(",
		/\d/,
		/\d/,
		/\d/,
		")",
		/\d/,
		/\d/,
		/\d/,
		"-",
		/\d/,
		/\d/,
		"-",
		/\d/,
		/\d/
	];
	const validationsSchema = yup.object().shape({
		name: yup.string()
			.min(3, 'Слишком короткое имя')
			.matches(
				/[а-я,А-Я]/,
				'Имя должно содержать только русские буквы'
			)
			.matches(/^([^0-9]*)$/gm, "'Имя должно содержать только буквы")
			.required('Обязательно к заполнению'),
		phone_number: yup.string()
			.required('Обязательно к заполнению')
			.transform(value => value.replace(/[^\d]/g, ''))
			.min(11, "Введите корректный номер"),
		email: yup.string()
			.email('Введите верный email')
			.required('Обязательно к заполнению'),
		check: yup.bool()
			.oneOf([true], 'Дайте согласие на обработку персональных данных'),
	});
	return (
		<>
			<Formik
				initialValues={{
					name: '',
					phone_number: '',
					email: '',
					comment: '',
					check: true
				}}
				validateOnBlur
				onSubmit={async ({name, phone_number, email, comment}) => { 
					const body: Order = {
						cart_id: cartId,
						name,
						phone_number,
						email,
						comment,
					};
					let res = await createOrder({body});
					if (res['error']) {
						setResultShow(true);
						setResultText({
							status: 'Ошибка!',
							text: `Попробуйте позже`
						});
					} else {
						setOrderSuccess(true);
					}
				}}
				validationSchema={validationsSchema}
			>
				{({
					handleSubmit,
					isSubmitting
				}) => (
					<Form className="cart_form" onSubmit={handleSubmit}>
						<div className="cart_form__input">
							<Field
								type="text"
								name="name"
								placeholder="Ваше имя" />
							<ErrorMessage name="name" component="p" className="cart_form__error" />
						</div>
						<div className="cart_form__input">
							<Field
								name="phone_number"
								render={({ field }) => (
										<MaskedInput
										{...field}
										mask={phoneNumberMask}
										id="phone_number"
										placeholder="Телефон"
										type="text"
										/>
								)}
							/>
							<ErrorMessage name="phone_number" component="p" className="cart_form__error" />
						</div>
						<div className="cart_form__input">
							<Field
								type="email"
								name="email"
								placeholder="E-mail" />
							<ErrorMessage name="email" component="p" className="cart_form__error" />
						</div>
						<p className="agreement">
							<Field 
								type="checkbox" 
								name="check" />
							<span>Даю согласие на обработку персональных данных. <Link to="/politika-konfidenczialnosti">Положение о защите персональных данных.</Link></span>
							<ErrorMessage name="check" component="p" className="cart_form__error" />
						</p>
						<div className="cart_form__textarea">
							<Field
								as="textarea"
								name="comment"
								placeholder="Комментарий" />
						</div>
						<button className="order_submit" disabled={isSubmitting}>Заказать</button>
					</Form>  
				)}   
			</Formik>  
			{resultShow && <ModalPopup setShow={setResultShow} addedClass="form_result">
				<FormResult status={resultText.status} text={resultText.text}/>
			</ModalPopup>}
		</>
	)
};
export default OrderForm;