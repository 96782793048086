import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Category, CategoryStore } from './types/Category';
import { Product, ProductList } from './types/Product';
import { CartItem, CartInfo } from './types/Cart';
import { Order } from './types/Order';
import { FormRequest } from './types/FormRequest';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/',
    prepareHeaders: (headers) => {
      headers.set('Accept', 'application/json');
      return headers;
    }
  }),
  tagTypes: ['Cart'],
  endpoints: (builder) => ({
    getCategory: builder.query<CategoryStore, void>({
      query: () => `category/`,
      transformResponse: (response: Category[]) => {
        let obj: CategoryStore['categoriesMap']  = {};
        const createMap = (el: Category) => {
          obj[el.id] = el;
          el.children.forEach(el => createMap(el));
        };
        response.forEach(createMap);
        return {
          categories: response,
          categoriesMap: obj
        };
      }
    }),
    getCategoryById: builder.query<Category, string>({
      query: (slug) => `category/${slug}/`,
    }),
    getProductsList: builder.query<ProductList, {slug?: string, limit: number, offset: number, name?: string}>({
      query: ({slug, limit, offset, name}) => {
        return {
          url: `product/`,
          params: {category_slug: slug, limit, offset, name},
        };
      }
    }),
    getProductById: builder.query<Product, string>({
      query: (slug) => `product/${slug}/`
    }),
    getCartInfo: builder.query<CartInfo, string>({
      query: (id) => `cart/${id}/`,
      providesTags: (result) =>
        [{ type: 'Cart', id: 'LIST' }]
    }),
    addToCart: builder.mutation<CartItem, { body: CartItem; id: string }>({
      query: ({body, id}) => ({
        url: `cart/add/${id}/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Cart', id: 'LIST' }]
    }),
    deleteFromCart: builder.mutation<CartItem, {  body: CartItem; id: string}>({
      query: ({body, id}) => ({
        url: `cart/remove/${id}/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Cart', id: 'LIST' }]
    }),
    clearCart: builder.mutation<CartItem[], any>({
      query: () => ({
        url: `cart/clear/`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'Cart', id: 'LIST' }]
    }),
    createOrder: builder.mutation<any, { body: Order}>({
      query: ({body}) => ({
        url: `order/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Cart', id: 'LIST' }]
    }),
    createFormRequest: builder.mutation<FormRequest, { body: FormRequest}>({
      query: ({body}) => ({
        url: `callback/`,
        method: 'POST',
        body,
      })
    }),
  }),
});

export const { 
  useGetCategoryQuery, useGetCategoryByIdQuery,
  useGetProductsListQuery, useGetProductByIdQuery,
  useGetCartInfoQuery, useAddToCartMutation, useDeleteFromCartMutation, useClearCartMutation,
  useCreateOrderMutation,
  useCreateFormRequestMutation
} = api;