import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useGetCategoryQuery } from 'services/api/api';
import { Category } from 'services/api/types/Category';

const MenuItem = ({el, isMobile, slug}: {el: Category, isMobile: boolean, slug: string[]}) => {
  const [isOpen, setIsOpen] = useState(false);
  const url = slug.join('/');
  const href =  `${url}/${el.slug}`;
  const showChildMenu = (evt) => {
    if (el.children.length && isMobile) {
      if (isOpen) {
        return true;
      } else {
        evt.preventDefault();
        setIsOpen(true);
      }
    }
  };
  return (
    <li className={el.children.length ? 'submenu' : ''}>
      <NavLink to={href} className={isOpen ? 'm-open' : ''} itemProp="url" onClick={showChildMenu}>{el.name}</NavLink>
      {el.children.length ? 
        <ul className={isOpen ? 'onhover' : ''}>{childrenList([...slug, el.slug], el.children, isMobile)}</ul>
      : ''}
    </li>
  )
};
const childrenList = (arr: string[], data: Category[], isMobile: boolean): React.ReactNode => {
  return data.map(el => {
    return (
      <MenuItem el={el} key={el.id} isMobile={isMobile} slug={arr}/>
    );
  });
};

const MenuCatalog = ({isMobile}) => {
  const {data, error, isLoading} = useGetCategoryQuery();

  return (
    <ul className="container" itemScope itemType="http://schema.org/SiteNavigationElement">
      {error ? (
        <>Ошибка</>
      ) : isLoading ? (
        <>Загрузка...</>
      ) : data ? (
        childrenList(['/catalog'], data.categories, isMobile)
      ) : null}
    </ul>
  );
};

export default MenuCatalog;