import React, {lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { YMInitializer } from 'react-yandex-metrika';
import Header from 'features/Header/Header';
import Footer from 'features/Footer/Footer';
import HomePage from 'features/HomePage/HomePage';
import DeliveryPage from 'features/DeliveryPage/DeliveryPage';
import ContactsPage from 'features/ContactsPage/ContactsPage';
import PoliticPage from 'features/PoliticPage/PoliticPage';
import PromoPage from 'features/PromoPage/PromoPage';
import SearchResultPage from 'features/Search/SearchResultPage';
import Cart from 'features/Cart/Cart';
import NotFound from 'features/Errors/NotFound';
import { ViewportProvider } from 'app/viewportContext';
import GdprModal from 'features/Modals/GdprModal';
import PromoModal from 'features/Modals/PromoModal';
import Spinner from 'shared/Spinner';
import QuestionModal from 'features/Modals/QuestionModal';
import ModalPopup from 'shared/ModalPopup';
import { ErrorBoundary } from 'features/Errors/ErrorBoundary';

const CatalogPage = lazy(() => import('features/Catalog/CatalogPage/CatalogPage'));
const CatalogListPage = lazy(() => import('features/Catalog/CatalogListPage/CatalogListPage'));
const CatalogItemPage = lazy(() => import('features/Catalog/CatalogItem/CatalogItemPage'));

function App() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [paddingTop, setPaddingtop] = useState(0);
  useEffect(() => {
    let cartId = localStorage.getItem("cartId");
    if (cartId == null) {
      cartId = Math.floor(Math.random()*999999).toString();
      localStorage.setItem("cartId", cartId);
    }
  }, []);

  return (
    <BrowserRouter>
      <ViewportProvider>
        <div className="App" style={{paddingTop: paddingTop}}>
          <YMInitializer accounts={[64704295]} options={{
            defer: true,
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true
          }} />
          <Header setPaddingtop={setPaddingtop} />
          <ErrorBoundary>
            <Suspense fallback={<Spinner/>}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/catalog" element={<CatalogPage />} />
                <Route path="/dostavka" element={<DeliveryPage />} />
                <Route path="/akczii" element={<PromoPage />} />
                <Route path="/kontaktyi" element={<ContactsPage />} />
                <Route path="/politika-konfidenczialnosti" element={<PoliticPage />} />
                <Route path="/catalog/:slug1/:slug2/:slug3" element={<CatalogListPage />} />
                <Route path="/catalog/:slug1/:slug2/:slug3/product/:item" element={<CatalogItemPage />} />
                <Route path="/catalog/:slug1/:slug2" element={<CatalogListPage />} />
                <Route path="/catalog/:slug1/:slug2/product/:item" element={<CatalogItemPage />} />
                <Route path="/catalog/:slug1" element={<CatalogListPage />} />
                <Route path="/catalog/:slug1/product/:item" element={<CatalogItemPage />} />
                <Route path="/product/:item" element={<CatalogItemPage />} />
                {/* <Route path="/catalog/:slug1/:slug2" element={<CatalogListPage />} >
                  <Route path="products/:item" element={<CatalogItemPage />} />
                </Route>
                <Route path="/catalog/:slug1" element={<CatalogListPage />} >
                  <Route path="products/:item" element={<CatalogItemPage />} />
                </Route> */}
                <Route path="/search/:keyword" element={<SearchResultPage />} />
                <Route path="/cart" element={<Cart />} />
                <Route path='*' element={<NotFound />}/>
              </Routes>
            </Suspense>
          </ErrorBoundary>
          <Footer/>
          <GdprModal/>
          <PromoModal/>
          <button className="question-link" onClick={() => setShowModal(true)}>
            <span className="question-link-icon"></span>
            <span className="question-link-text">Задать вопрос</span>
          </button>
          {showModal && <ModalPopup setShow={setShowModal}><QuestionModal setShow={setShowModal} /></ModalPopup>}
        </div>
      </ViewportProvider>
    </BrowserRouter>
  );
}

export default App;
