import React, {
	FC,
	ReactNode,
	useState,
	useEffect,
	createContext
} from 'react';

type ViewportProps = {
	children: ReactNode;
};

const ViewportContext = createContext(undefined);

const ViewportProvider: FC<ViewportProps> = ({children}) => {
	const [width, setWidth] = useState<number>(window.innerWidth);

	useEffect(() => {
		const handleWindowResize = () => setWidth(window.innerWidth);
		window.addEventListener("resize", handleWindowResize);
		return () => window.removeEventListener("resize", handleWindowResize);
	}, []);

	return (
		<ViewportContext.Provider value={width}>
			{children}
		</ViewportContext.Provider>
	);
};
export { ViewportContext, ViewportProvider }