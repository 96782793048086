import { Link, NavLink, useLocation } from 'react-router-dom';
import { useEffect, useState, useRef, useContext } from 'react';
import Menu from 'features/Menu/Menu';
import MenuCatalog from 'features/Menu/MenuCatalog';
import MiniCart from 'features/Cart/MiniCart';
import Search from '../Search/Search';
import AskModal from 'features/Modals/AskModal';
import ModalPopup from 'shared/ModalPopup';
import PopupForm from 'shared/PopupForm';
import { ViewportContext } from 'app/viewportContext';
import logoImg from 'assets/images/logo_new.png';
import {
	CSSTransition ,
	TransitionGroup
  } from 'react-transition-group';
import ym from 'react-yandex-metrika';

const Header = ({setPaddingtop}) => {
	const breakpoint = 991;
	const width = useContext(ViewportContext);
	const location = useLocation();
	const [isMobile, setIsMobile] = useState(false);
	const [showBurgerMenu, setShowBurgerMenu] = useState(false);
	const [isShowCatalogMenu, setIsShowCatalogMenu] = useState(false);
	const [searchModalShow, setSearchModalShow] = useState(false);
	const [askModalShow, setAskModalShow] = useState(false);
	const [isFixed, setIsFixed] = useState(false);
	const [isShowFixedMenu, setIsShowFixedMenu] = useState(false);
	const [freeheight, setFreeheight] = useState(0);
	const headerRef = useRef(null);
	let startHeaderHeight;

	useEffect(() => {
		setIsMobile(width <= breakpoint);
	}, [width]);

	useEffect(() => {
		setShowBurgerMenu(false);
		setIsShowCatalogMenu(false);
		setIsShowFixedMenu(false);
		setSearchModalShow(false);
		setAskModalShow(false);
	}, [location]);

	const handleScroll = () => { 
		if (window.pageYOffset > headerRef.current.clientHeight) {
			setIsFixed(true);
			setPaddingtop(startHeaderHeight);
		} else {
			setIsFixed(false);
			setPaddingtop(0);
		}
	};

	useEffect(() => {
		startHeaderHeight = headerRef.current.clientHeight;
		window.addEventListener('scroll', handleScroll)
		return () => {
			startHeaderHeight = 0;
			window.removeEventListener('scroll', handleScroll)
		}
	}, []);

	const showCatalogMenu = () => {
		setIsShowCatalogMenu(true);
		setFreeheight(window.innerHeight - headerRef.current.clientHeight);
	};
	const hideCatalogMenu = () => setIsShowCatalogMenu(false);

	return (
		<header ref={headerRef} className={isFixed ? 'fixed' : ''}>
			<div className="dark dark-header">
				<div className="container">
					<div className="row middle-vr">
						<div className="col-xs-12 col-sm-3 header-logo"> 
							<Link to="/" className="logo"><img src={logoImg} alt="Универсал логотип" title="Универсал" /></Link> 
						</div>
						<div className="col-xs-12 col-sm-9 header-contacts">
							<div className="row">
								<div className="col-xs-12 col-sm-3">
									<div className="header-contacts-item header-contacts-item-tel"> 
										<a href="tel:84862510038" rel="nofollow noopener noreferrer" onClick={() => {ym('reachGoal', 'header_tel')}}>
											<span className="glyphicon glyphicon-earphone"></span>(4862) <b>51-00-38</b>
										</a>
										<a className="header-contacts-item-mail" href="mailto:universal57@bk.ru" rel="nofollow noopener noreferrer" target="_blank" onContextMenu={() => {ym('reachGoal','header_mail')}}>universal57@bk.ru</a>
									</div>
								</div>
								<div className="col-xs-12 col-sm-5">
									<div className="header-contacts-item header-contacts-item-adr"> 
										<a href="/kontaktyi"><span className="glyphicon glyphicon-map-marker"></span> г. Орел, Наугорское шоссе, д. 19, стр. 1</a>
										<div className="header-contacts-small header-contacts-small--left"> 
											<NavLink to="/kontaktyi" className="callback-link">Посмотреть все адреса</NavLink> 
										</div>
										<div className="header-contacts-small">
											<button className="callback-link" onClick={() => {ym('reachGoal','header_form'); setAskModalShow(true)}}>Оставить заявку</button> 
										</div>
									</div>
								</div>
								<Search addedClassNames='col-xs-12 col-sm-3 header-search-wrap' />
								<MiniCart/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={`header-nav-wrap ${isShowFixedMenu ? 'menu-opened' : ''}`}>
				<div className="container">
					<div className="fixed__btn" onClick={() => setIsShowFixedMenu(state => !state)}>
						<div className={`menu-icon menu-icon-3 ${isShowFixedMenu ? 'opened' : ''}`}>
							<div className="bar bar-1"></div>
							<div className="bar bar-2"></div>
							<div className="bar bar-3"></div>
						</div>
						<span>Меню</span>
					</div>
					<a href="/" className="fix_logo"><img src={logoImg} alt="Универсал логотип" title="Универсал" /></a>
					{(!isFixed || isMobile || (isFixed && isShowFixedMenu)) && 
						<div className="row middle-vr">
							<div className="col-xs-2 col-sm-2 header-nav-container"> 
								<button className="mobile-burger" onClick={() => setShowBurgerMenu(state => !state)}>
									<span></span> 
									<span></span> 
									<span></span> 
								</button>
								{(!isMobile || (isMobile && showBurgerMenu)) &&
									<nav className="header-nav" id="main_nav" data-burger-menu> 
										<button className="close-nav" onClick={() => setShowBurgerMenu(state => !state)}>
											<span className="glyphicon glyphicon-remove"></span>
										</button>
										<Menu showCatalogMenu={showCatalogMenu} hideCatalogMenu={hideCatalogMenu} isMobile={isMobile} />
									</nav>
								}
							</div>
							<button className="btn fix_cat_btn" onClick={() => setIsShowCatalogMenu(state => !state)}>Каталог</button>
							<div className="col-xs-10 col-sm-10 header-search-btn"> 
								<button className="message-link btn float-right" onClick={() => setSearchModalShow(true)}>
									<span className="glyphicon glyphicon-search"></span> 
									Поиск
								</button> 
								<Search />
							</div>
						</div>
					}
					<Search addedClassNames='fix-search-wrap' />
					<a href="tel:84862510038" rel="nofollow noopener noreferrer" className="fix_tel" onClick={() => {ym('reachGoal', 'header_tel')}}>
						<span className="glyphicon glyphicon-earphone"></span>(4862) <b>51-00-38</b>
					</a>
					<MiniCart visible={isFixed} />
				</div>
				<TransitionGroup component={null}>
					{isShowCatalogMenu && 
						<CSSTransition timeout={{
							enter: 100,
							exit: 300,
						}}>
							<div className="smenu anim" onMouseLeave={hideCatalogMenu} style={{maxHeight: freeheight}}>
								<MenuCatalog isMobile={isMobile}/>
							</div>
						</CSSTransition>
					}
				</TransitionGroup>

				{/* <div className="search_modal__result" id="search_dropdown2"></div> */}
			</div>
			{searchModalShow &&
				<ModalPopup addedClass='modal--search' setShow={setSearchModalShow}>
					<div id="search" className="modals-content">
						<div className="modals-inner">
							<div className="modals-inner-capt text-center">Поиск товара</div>
							<Search placeholder='Поиск' />
						</div>
					</div>
				</ModalPopup>
			}
			{askModalShow && <PopupForm setShow={setAskModalShow}><AskModal setShow={setAskModalShow}/></PopupForm>}
		</header>
	)
}
export default Header;