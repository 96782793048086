const FormResult = ({status, text}) => {
    return (
        <div id="result">
			<div className="modals-inner">
				<p className="modals-inner-capt text-center">{status}</p>
                <p>{text}</p>
            </div>
        </div>
    )
};
export default FormResult;