import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useGetCategoryQuery } from 'services/api/api';
import { ViewportContext } from 'app/viewportContext';
import { Category } from 'services/api/types/Category';
import LoadingBlock from 'shared/LoadingBlock';

const MenuItem = ({slug, el}: {slug: string[], el: Category,}) => {
  const location = useLocation();
  const breakpoint = 991;
  const width = useContext(ViewportContext);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsMobile(width <= breakpoint);
  }, [width]);

  const showChildMenu = (evt) => {
    if (el.children.length && isMobile) {
      if (isOpen) {
        return true;
      } else {
        evt.preventDefault();
        setIsOpen(true);
      }
    }
  };
  const url = slug.join('/');
  const href = `/catalog/${url}/${el.slug}`;
  return (
    <li className={`${el.children.length ? 'submenu' : ''}  ${location.pathname.includes(href) ? 'active' : ''}`}>
      <NavLink 
        to={href} 
        className={isOpen ? 'm-open' : ''} 
        itemProp="url" 
        onClick={showChildMenu}
      >{el.name}</NavLink>
      {el.children.length ? 
        <ul 
          className={isOpen ? 'onhover' : ''}
        >{childrenList([...slug, el.slug], el.children)}</ul>
      : ''}
    </li>
  )
};
const childrenList = (arr: string[], data: Category[]): React.ReactNode => {
  return data.map(el => {
    return (
      <MenuItem el={el} key={el.id} slug={arr}/>
    );
  });
};
const FirstLevelMenuItem = ({el, setHoverItemId, hoverItemId}: {el: Category, setHoverItemId: (number) => void, hoverItemId: number}) => {
  const breakpoint = 991;
  const width = useContext(ViewportContext);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  let leaveTimer;
  let hoverTimer;
  
  useEffect(() => {
    setIsMobile(width <= breakpoint);
  }, [width]);

  useEffect(() => {
    setIsHover(false);
    setIsOpen(false);
  }, [location]);

  if (isHover && hoverItemId !== el.id) {
    console.log(hoverItemId, el.id);
    setIsHover(false);
  }
      
  const showChildMenu = (evt) => {
    if (el.children.length && isMobile) {
      if (isOpen) {
        return true;
      } else {
        evt.preventDefault();
        setIsOpen(true);
      }
    }
  };
  const addHover = () => {
    if (!isMobile) {
      hoverTimer = setTimeout(function() {
        clearTimeout(leaveTimer);
        setIsHover(true);
        setHoverItemId(el.id);
      }, 200);
    }
  };
  const removeHover = () => {
    if (!isMobile) {
      clearTimeout(hoverTimer);
      leaveTimer = setTimeout(function() {
        setIsHover(false);
      }, 200);
    }
  };
  const addSubmenuHover = () => {
    if (!isMobile) {
      clearTimeout(leaveTimer);
    }
  };

  return (
    <li className={`${el.children.length ? 'submenu' : ''} ${location.pathname.includes(`/catalog/${el.slug}`) ? 'active' : ''}`}>
      <NavLink 
        to={`/catalog/${el.slug}`} 
        className={isOpen ? 'm-open' : ''} 
        onClick={showChildMenu}
        onMouseEnter={addHover}
        onMouseLeave={removeHover}
        itemProp="url" >
        {el.name}
      </NavLink>
      {el.children.length ? 
        <ul 
          onMouseEnter={addSubmenuHover}
          onMouseLeave={removeHover}
          className={isOpen || isHover ? 'onhover' : ''}>
          {childrenList([el.slug], el.children)}
        </ul>
      : ''}
    </li>
  )
};

const AsideMenu = () => {
  const breakpoint = 991;
  const width = useContext(ViewportContext);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const {data, isLoading} = useGetCategoryQuery();
  const [hoverItemId, setHoverItemId] = useState<number>(0);
  const [isShow, setIsShow] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    setIsMobile(width <= breakpoint);
  }, [width]);

  useEffect(() => {
    setIsShow(false);
  }, [location]);

  const listItems = data 
    ?   data.categories.map(el => <FirstLevelMenuItem el={el} key={el.id} setHoverItemId={setHoverItemId} hoverItemId={hoverItemId} />)
    :   null;

  return (
    <div className="col-xs-12 col-sm-12 col-md-3 catalog_aside">
      <div className="text-center menu-cat-wrap">
        <button className="btn" onClick={() => setIsShow(state => !state)}>Каталог <span className="glyphicon glyphicon-chevron-down"></span></button>
      </div>
      <LoadingBlock isLoading={isLoading}>
        {(!isMobile || (isShow && isMobile)) && 
          <div id="catalog_wrap" className="catalog_wrap">
            <button className="close-nav close-nav-catalog" onClick={() => setIsShow(false)}><span className="glyphicon glyphicon-remove">&nbsp;</span></button>
            <div className="catalog-capt"> Каталог товаров </div>
            <ul className='catalog-block'>
              {listItems}
            </ul> 
          </div>
        }
      </LoadingBlock>
    </div>
  )
}

export default AsideMenu;