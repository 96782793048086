import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="errorpage">
      <div className="container main-content center-text">
        <div className="main-content-inners ">
          <div className="title">
            <h1>ошибка <b>404</b></h1>
          </div>
          <div className="text">
            <p>Извините! Страница, которую Вы ищете, не может быть найдена...</p>
            <div className="btns">
              <Link to="/">Главная</Link>
              <Link to="/catalog">Каталог</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
export default NotFound;