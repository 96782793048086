import { Link } from "react-router-dom";
import { useState } from "react";
import { useCreateFormRequestMutation } from "services/api/api";
import { FormRequest } from "services/api/types/FormRequest";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import MaskedInput from "react-text-mask";
import ClientCaptcha from "react-client-captcha";
import ModalPopup from "shared/ModalPopup";
import FormResult from "shared/FormResult";

const QuestionModal = ({ setShow }: { setShow: (boolean) => void }) => {
	const [captchaCode, setCaptcha] = useState();
	const [resultShow, setResultShow] = useState(false);
	const [resultText, setResultText] = useState({
		status: '',
		text: ''
	});
	const [createFormRequest] = useCreateFormRequestMutation();
	const phoneNumberMask = [
		"+",
		"7",
		"(",
		/\d/,
		/\d/,
		/\d/,
		")",
		/\d/,
		/\d/,
		/\d/,
		"-",
		/\d/,
		/\d/,
		"-",
		/\d/,
		/\d/
	];
	const validationsSchema = yup.object().shape({
		name: yup.string()
			.min(3, 'Слишком короткое имя')
			.matches(
				/[а-я,А-Я]/,
				'Имя должно содержать только русские буквы'
			)
			.matches(/^([^0-9]*)$/gm, "'Имя должно содержать только буквы")
			.required('Обязательно к заполнению'),
		phone_number: yup.string()
			.required('Обязательно к заполнению')
			.transform(value => value.replace(/[^\d]/g, ''))
			.min(11, "Введите корректный номер"),
		check: yup.bool()
			.oneOf([true], 'Дайте согласие на обработку персональных данных'),
		captcha: yup.string()
			.oneOf([captchaCode, null], 'Неверный код')
			.required('Обязательно к заполнению'),
		comment: yup.string()
			.required('Обязательно к заполнению')
			.test(
				'nourl',
				'Текст сообщения введен некорректно',
				(value) => {
					if (!value) {
						return true;
					}
					try {
						return !value.match(/(www\.)|(https?:)|(\.ru)|(\.com)|(\.рф)/)
					} catch {
						return false;
					}
				}
			)
	});
	return (
		<>
			{resultShow && resultText.status === 'Спасибо!'
				? <ModalPopup setShow={setShow} addedClass="form_result">
					<FormResult status={resultText.status} text={resultText.text} />
				</ModalPopup>
				:
				<div id="question">
					<div className="modals-inner">
						<div className="modals-inner-capt text-center">Задать вопрос</div>
						<Formik
							initialValues={{
								name: '',
								phone_number: '',
								comment: '',
								check: true
							}}
							validateOnBlur
							onSubmit={async ({ name, phone_number, comment }, { resetForm }) => {
								const body: FormRequest = {
									name,
									phone_number,
									comment
								};
								let res = await createFormRequest({ body });
								if (res['error']) {
									setResultShow(true);
									setResultText({
										status: 'Ошибка!',
										text: `Попробуйте позже`
									});
								} else {
									resetForm();
									setResultShow(true);
									setResultText({
										status: 'Спасибо!',
										text: `Ваша заявка отправлена`
									});
								}
							}}
							validationSchema={validationsSchema}
						>
							{({
								handleSubmit,
								isSubmitting
							}) => (
								<Form onSubmit={handleSubmit} className="mr-tp-20 text-center ajax_form af_call feedback" id="questionform">
									<div className="form-group">
										<Field
											type="text"
											name="name"
											placeholder="Ваше имя" />
										<ErrorMessage name="name" component="span" className="error-el left-text" />
									</div>
									<div className="form-group">
										<Field name="phone_number">
											{({ field }) => <MaskedInput
												{...field}
												mask={phoneNumberMask}
												id="phone_number"
												placeholder="Телефон"
												type="text"
											/>
											}
										</Field>
										<ErrorMessage name="phone_number" component="span" className="error-el left-text" />
									</div>
									<div className="form-group all">
										<Field
											as="textarea"
											name="comment"
											placeholder="Ваш вопрос" />
										<ErrorMessage name="comment" component="span" className="error-el left-text" />
									</div>
									<div className="captcha_row">
										<ClientCaptcha
											captchaCode={setCaptcha}
											chars="1234567890"
											charsCount={4}
											width={80}
											height={35}
											fontSize={20}
											backgroundColor="#f5f5f5"
											fontColor="green"
										/>
										<Field
											type="text"
											name="captcha"
											placeholder="Код с картинки*" />
										<ErrorMessage name="captcha" component="span" className="error-el left-text" />
									</div>
									<div className="policy-form policy-check text-left">
										<Field
											type="checkbox"
											name="check" />
										<span>Отправляя любую форму на сайте, вы даете согласие на обработку персональных данных -<br /> <Link to="/politika-konfidenczialnosti" itemProp="url">Положение о защите персональных данных</Link></span>
										<ErrorMessage name="check" component="small" className="error-el left-text" />
									</div>
									<button type="submit" disabled={isSubmitting}>Отправить</button>
								</Form>
							)}
						</Formik>
						{resultShow && <ModalPopup setShow={setResultShow} addedClass="form_result">
							<FormResult status={resultText.status} text={resultText.text} />
						</ModalPopup>}
					</div>
				</div>
			}</>
	)
};
export default QuestionModal;