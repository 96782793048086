import { Link } from "react-router-dom";
import {useGetCartInfoQueryInitial} from "app/hooks";

const MiniCart = ({visible = true}: {visible?: boolean}) => {
	const cartId = localStorage.getItem('cartId');
	const {data, isLoading} = useGetCartInfoQueryInitial(cartId);
	let quantity = 0;

	if (data) {
		data.items.forEach(el => quantity = quantity + el.quantity);
	}

	return (
		<Link to="/cart" className="header_cart" style={{display: visible ? '': 'none'}}>
			{isLoading
				?   <span className="num">0</span>
				:   <span className="num">{quantity}</span>
			}
		</Link>
	)
};
export default MiniCart;