import { NavLink } from 'react-router-dom';

const Menu = ({showCatalogMenu , hideCatalogMenu, isMobile}) => {
  const handleMouseEnter = () => {
    if (!isMobile) showCatalogMenu();
  }
  return (
    <ul className="header-nav-list" itemScope itemType="http://schema.org/SiteNavigationElement">
      <li><NavLink to="/" itemProp="url" onMouseEnter={hideCatalogMenu}>Главная</NavLink></li>
      <li><NavLink to="/catalog" itemProp="url" onMouseEnter={handleMouseEnter}>Каталог</NavLink></li>
      <li><NavLink to="/akczii" itemProp="url" onMouseEnter={hideCatalogMenu}>Акции</NavLink></li>
      <li><NavLink to="/dostavka" itemProp="url" onMouseEnter={hideCatalogMenu}>Доставка</NavLink></li>
      <li><NavLink to="/kontaktyi" itemProp="url" onMouseEnter={hideCatalogMenu}>Контакты</NavLink></li>
    </ul>
  );
};

export default Menu;