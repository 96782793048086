import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetProductsListQuery } from "services/api/api";
import { Link } from "react-router-dom";


const Search = (props:
		{ addedClassNames?: string, placeholder?: string }
	) => {
	const [val, setVal] = useState<string>('');
	const {data} = useGetProductsListQuery({name: val.replaceAll("%2f", "/"), limit: 10, offset: 0}, {skip: !val});
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		setVal('');
	}, [location]);

	const handleSubmit = (e) => {
		e.preventDefault();
		navigate(`/search/${val}`);
	};
	const handleInputChange = (e) => {
		setVal(e.target.value.replaceAll("/", "%2f"));
	};

	return (
		<div className={props.addedClassNames}>
			<form className="header-search" onSubmit={handleSubmit}>
				<input type="text" name="q" id="search_input1" autoComplete="off" required value={val.replaceAll("%2f", "/")} onInput={handleInputChange} placeholder={props.placeholder}/>
				<button type="submit">Поиск</button>
			</form>
			{val && data &&
				<div className="search_modal__result">
					{data.results.length 
						?   <div className="search_modal__block">
								<p className="search_modal__block_header">
									Товары
									<Link to={`/search/${val}`}>Все товары</Link>
								</p>
								<ul>
									{data.results.map(el => <li key={el.id}><Link to={`/product/${el.slug}`}>{el.name}</Link></li>)}
								</ul>
							</div>
						:   <p>Ничего не найдено.</p>
					}
				</div>
			}
		</div>
	);
};
export default Search;
