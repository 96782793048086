import { Link, useNavigate } from 'react-router-dom';
import HeadTags from "shared/HeadTags";
import { useEffect } from 'react';
import ym from 'react-yandex-metrika';

const PoliticPage = () => {
	const navigate = useNavigate();

	useEffect(() => {
		ym('hit', '/politika-konfidenczialnosti');
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}, [navigate]);

	return (
		<div className="container main-content mr-tp-20 mr-bt-20">
			<HeadTags
				title='Положение о защите персональных данных ТЦ "Универсал"'
				metaDescription='Политика конфиденциальности'
			/>
			<ul className="breadcrumbs" itemScope itemType="http://schema.org/BreadcrumbList">
				<li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
					<Link to="/" itemProp="item">Главная</Link>
					<meta itemProp="position" content="1" />
				</li>
				<li className="breadcrumb-item active" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
					<span itemProp="item">Политика конфиденциальности</span>
					<meta itemProp="position" content="2" />
				</li>
			</ul>
			<h1 className="mr-tp-20">Политика конфиденциальности</h1>
			<p>г. Орел &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; «12» января 2019 г.</p>
			<p>Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую сайт «universal57.ru», расположенный на доменном имени universal57.ru, может получить о Пользователе во время использования сайта.</p>
			<p><strong>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</strong></p>
			<p>1.1 В настоящей Политике конфиденциальности используются следующие термины:</p>
			<p>1.1.1. «Администрация сайта Интернет-магазина (далее – Администрация сайта) » – уполномоченные сотрудники на управления сайтом, действующие от имени universal57.ru, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
			<p>1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).</p>
			<p>1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
			<p>1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</p>
			<p>1.1.5. «Пользователь сайта (далее - Пользователь)» – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее данный сайт.</p>
			<p>1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.</p>
			<p>1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</p>
			<p><strong>2. ОБЩИЕ ПОЛОЖЕНИЯ</strong></p>
			<p>2.1. Использование Пользователем сайта означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.</p>
			<p>2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта.</p>
			<p>2.3.Настоящая Политика конфиденциальности применяется только к сайту universal57.ru. Данный ресурс не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте организации.</p>
			<p>2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта организации.</p>
			<p><strong>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</strong></p>
			<p>3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта организации по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта при отправке любой формы с сайта.</p>
			<p>3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения любой формы Сайте организации universal57.ru и включают в себя следующую информацию:</p>
			<p>3.2.1. фамилию, имя, отчество Пользователя;</p>
			<p>3.2.2. контактный телефон Пользователя;</p>
			<p>3.2.3. адрес электронной почты (e-mail);</p>
			<ul>
				<li>IP адрес;</li>
				<li>информация из cookies;</li>
				<li>информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);</li>
				<li>реферер (адрес предыдущей страницы).</li>
			</ul>
			<p>3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта организации.</p>
			<p>3.3.2. Сайт осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью выявления и решения технических проблем.</p>
			<p>3.4. Любая иная персональная информация неоговоренная выше (история покупок, используемые браузеры и операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.</p>
			<p><strong>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</strong></p>
			<p>4.1. Персональные данные Пользователя Администрация сайта организации может использовать в целях:</p>
			<p>4.1.1. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработка запросов и заявок от Пользователя.</p>
			<p>4.1.2. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</p>
			<p>4.1.3. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</p>
			<p>4.1.4. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием Сайта.</p>
			<p>4.1.5. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени Интернет-магазина или от имени партнеров организации.</p>
			<p>4.1.6. Предоставления доступа Пользователю на сайты или сервисы партнеров Интернет-магазина с целью получения продуктов, обновлений и услуг.</p>
			<p><strong>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</strong></p>
			<p>5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</p>
			<p>5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи.</p>
			<p>5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.</p>
			<p>5.4. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.</p>
			<p>5.5. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>
			<p>5.6. Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</p>
			<p><strong>6. ОБЯЗАТЕЛЬСТВА СТОРОН</strong></p>
			<p><strong>6.1. Пользователь обязан:</strong></p>
			<p>6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом.</p>
			<p>6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.</p>
			<p><strong>6.2. Администрация сайта обязана:</strong></p>
			<p>6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.</p>
			<p>6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.</p>
			<p>6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.</p>
			<p>6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.</p>
			<p><strong>7. ОТВЕТСТВЕННОСТЬ СТОРОН</strong></p>
			<p>7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.</p>
			<p>7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:</p>
			<p>7.2.1. Стала публичным достоянием до её утраты или разглашения.</p>
			<p>7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.</p>
			<p>7.2.3. Была разглашена с согласия Пользователя.</p>
			<p><strong>8. РАЗРЕШЕНИЕ СПОРОВ</strong></p>
			<p>8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта Интернет-магазина и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).</p>
			<p>8.2 .Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</p>
			<p>8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Российской Федерации.</p>
			<p>8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Российской Федерации.</p>
			<p><strong>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</strong></p>
			<p>9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.</p>
			<p>9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.</p>
			<p>9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать в разделе Контакты</p>
			<p>9.4. Действующая Политика конфиденциальности размещена на странице по адресу http://universal57.ru/politika-konfidenczialnosti.</p>
			<p>&nbsp;</p>
			<p className="mr-bt-40">Обновлено «17» августа 2020 г.</p>
		</div>
	)
}
export default PoliticPage;